import React, {Component} from "react"
import ERISchedule from "../data/pdf/ERI_Schedule.pdf"
import ERIBrochure from "../data/pdf/ERI_Brochure.pdf"

class Download extends Component{
	constructor(props) {
	    super(props)
	    let href = "";
	    switch(props.to){
	    	case "schedule":
	    		href = ERISchedule;
	    		break;
	    	case "brochure":
	    		href= ERIBrochure;
	    }



	    this.inputRef = React.createRef("something");
	    this.button = <a  ref={this.inputRef} href={href} />;
	}
	componentDidMount() {
		this.button.ref.current.click()
	}
	render() {
	 	return this.button
	}
}
export default Download; 