import React from 'react'
import { Helmet } from 'react-helmet'


class MyComponent extends React.PureComponent {
  render () {
  	const TITLE = process.env.REACT_APP_PAGE_TITLE

    return (
      <>
        <Helmet>
          <title>{ TITLE }</title>
        </Helmet>
        
      </>
    )
  }
}
export default MyComponent