import React, {Component} from "react"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import * as CatalogAPI from "../utils/CatalogAPI"

 import {

  Link
} from "react-router-dom";
class AddAuthor extends Component {
	constructor(props){
		super(props)
		this.backText="< Back"
		this.passwordInput = React.createRef();

		this.state= {
			authorid:this.props.authorid,
			id:this.props.authorid,
			submitMessage:"none",
			form_values:{},
			correctPassword:false,
			checkingPassword:false

		}
		this.imageUtils = require("../utils/ImageUtils")
		this.images = this.imageUtils.getImagesFromDir("admin")

	}
	
	componentDidMount() { 
		
		if(this.state.authorid !== 'new'){
			fetch(CatalogAPI.author_url(this.state.authorid)).then(data => data.json()).then(data =>{
						this.setState({
							img:this.imageUtils.getAuthorImage(this.state.authorid),
							post_url:CatalogAPI.author_update_url(this.state.authorid),
						})
						this.setState(data.author)
			})
		} else {
			this.setState({post_url:CatalogAPI.author_create_url})
		}
					

	}
	


	handleFileChange = (event) => { 
		console.log(event.target.files)
		this.setState({
			"img":URL.createObjectURL(event.target.files[0]),
			"new_author_image":event.target.files[0]
		})
	}

	handleChange = (event) => {
		let newState = this.state.form_values
		newState[event['target']['id']] = event.target.value
		this.setState({"form_values":newState})
		console.log(this.state)
	}

	handleSubmit = (e) => {
		e.preventDefault()

	   const formData = new FormData()
	   formData.append("password",this.state.password)
	   formData.append("content", JSON.stringify(this.state.form_values))

	   if ('new_author_image' in this.state) {
	  	 formData.append("img", this.state.new_author_image)
	   }
	   
	  const response =  fetch(this.state.post_url, {
	    method: 'POST', // *GET, POST, PUT, DELETE, etc.
	    mode: 'cors', // no-cors, *cors, same-origin
	    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
	    credentials: 'same-origin', // include, *same-origin, omit
	    headers: {
	      //'Content-Type': 'application/json'
	       //'Content-Type': 'multipart/form-data',
	    },
	    redirect: 'follow', // manual, *follow, error
	    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
	    body: formData // body data type must match "Content-Type" header
	  }).then( response => {
	  		if(response.status >=400){
	  			this.setState({submitMessage:"Error!"})
	  		} else {
	  			this.setState({submitMessage:"Saved!"})
	  			setTimeout(() => {  window.location.href = "/admin/authors"; }, 1000);
	  		}


	  })
	}

	checkPassword = (e) => {
		e.preventDefault()
		const formData = new FormData()

	    formData.append("password", this.state.password)
	    this.setState({checkingPassword:true})

		fetch(CatalogAPI.checkPassword(), {
	    method: 'POST', // *GET, POST, PUT, DELETE, etc.
	    mode: 'cors', // no-cors, *cors, same-origin
	    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
	    credentials: 'same-origin', // include, *same-origin, omit
	    headers: {
	      //'Content-Type': 'application/json'
	       //'Content-Type': 'multipart/form-data',
	    },
	    redirect: 'follow', // manual, *follow, error
	    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
	    body: formData // body data type must match "Content-Type" header
	  }).then( response => {
	  		    this.setState({checkingPassword:false})

	  		if(response.status >=400){this.setState({addShake:true})

	  			setTimeout(() => {  
	  				this.passwordInput.current.value=''
					this.setState({addShake:false})
					this.passwordInput.current.focus()

				}, 1000);
	  		} else {
	  			
	  				this.setState({submitMessage:"none","correctPassword":true})

	  			
	  		}


	  })
	}

	render() {
	console.log(this.state)
	let authorImage = <img className="catalog-image-thumbnail" src={this.state.img} alt="" />
	let submitShow = this.state.submitMessage == "none" ? "hidden ":"submit-message-bar ";
	let submitResultClass = this.state.submitMessage == "success" ? "red":"green"
	let submitMessageBarClass = submitShow+submitResultClass
	return (
		<div className="admin">
			<div className={submitMessageBarClass}><span>{this.state.submitMessage}</span></div>
	      
	      <div className={this.state.correctPassword ?  'password-wrapper hidden':'password-wrapper'}>
		      <form className="password" autocomplete="on" onSubmit={(e)=>this.checkPassword(e)}>
		        <h2>Authentication Required</h2>
		        <input style={this.state.addShake ? {"animation": "shake 0.5s",boxShadow: "0 0 0.5em red"}:{}} ref={this.passwordInput} type="password" placeholder="Password..." onChange={(e) => {this.setState({password:e.target.value})}} autofocus="true"/>
		        <img className={this.state.checkingPassword ? "thinking-wheel":"hidden"} src={this.images['thinking']} />

		        <br/>
		          <button className="submit-password" type="submit">Submit</button>
		          <Link className="cancel-password" to="/admin">Cancel</Link>
		      </form>
	      </div>

			<main className="site-content eri-colors add-series"> 
				
				<h1>Author</h1>
				<Form onSubmit={this.handleSubmit}>
				<div >
					 <Form.Group controlId="name">
					    <Form.Label>Name</Form.Label>
					    <Form.Control type="text" defaultValue={this.state.name} placeholder="Full Name..."  onChange={this.handleChange}/>
					  </Form.Group>
					  <Form.Group controlId="short_name">
					    <Form.Label>Table Of Contents Name or Blank</Form.Label>
					    <Form.Control type="text"  defaultValue={this.state.short_name} placeholder="e.g. Demacopoulos..."  onChange={this.handleChange}/>
					  </Form.Group>
					  <Form.Group controlId="long_name">
					    <Form.Label>Author Long Name</Form.Label>
					    <Form.Control  defaultValue={this.state.long_name} type="text" placeholder="e.g. George Demacopoulos, PhD"  onChange={this.handleChange}/>
					  </Form.Group>
					  <Form.Group controlId="biotext">
					    <Form.Label>Author Bio</Form.Label>
					    <Form.Control  defaultValue={this.state.biotext} as="textarea" placeholder="George Demacopoulos is the..."  onChange={this.handleChange}/>
					  </Form.Group>
					  <Form.Group controlId="authorImage">
					    <Form.Label>Change Author Picture</Form.Label>
					    {authorImage}
				    	<Form.File id="authorImage"  onChange={this.handleFileChange} />
					  </Form.Group>
				</div>
				  <br/>
				  <br/>

				<div className="btns-wrapper">
				  	<Button variant="primary" type="submit" className="save-button">Save</Button>
				  	<Link to="/admin" className="cancel-link"><Button variant="primary" className="cancel-button">Cancel</Button></Link>
				</div>				
				</Form>
			</main>
			</div>
		
	)
	}
}

export default AddAuthor 

//				  

