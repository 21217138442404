import React, {Component} from "react"
import * as CatalogAPI from "../utils/CatalogAPI"
import MenuBookIcon from '@material-ui/icons/MenuBook';

 import {

  Link
} from "react-router-dom";
import logo from "../data/images/home/logo1.png"
class Series extends Component {
  constructor(props){
    super(props)
		window.scrollTo(0, 0);
		 
		this.lectureUtils = require("../utils/LectureUtils")
		let jsonData={
			authors:[""],
			lectures:["",""],
			series_id:"new"
		}
		this.state = {
			jsonData:jsonData
		}
			this.imageUtils = require("../utils/ImageUtils")
	}
	componentDidMount() { 
		let serieskey = this.props.serieskey
		console.log(serieskey)

		fetch(
			CatalogAPI.series_url(serieskey)
      	).then(res =>res.json()).then( data => {
      		let resourcesMap = this.lectureUtils.getResourcesMap(data.resources)
      		this.setState ( {
		 		lectureItems:data.lectures.map((element,key) => this.lectureUtils.getLectureJSX(element,key,resourcesMap)),
				sidebarLectures: data.lectures.map((element,key) => this.lectureUtils.getSidebarJSX(element,key)),
				speakerBios: data.authors.map((element,key) => this.lectureUtils.getAuthorJSX(element,key, this.imageUtils.getAuthorImage(element.author_id))),
 				jsonData:data,
 				resources: this.lectureUtils.getResources(data.resources)
 			})
 		})
 	}

 	render() {
 		console.log(this.state.jsonData)
  		//let sidebarHeader = this.lectureUtils.getNumberSpelled(this.state.jsonData.lectures.length)+" Sessions"
  		let sidebarHeader = "Table of Contents"
  		let style = {}
  		if (process.env.REACT_APP_BANNER == "false"){
	        style = {paddingTop:"0px"}
	    }
		return (
			<div className="series" style={style}>
				
			<div className="site body">
				<main className="site-content eri-colors eri-series">
						<h1 className="series-title">
								{this.state.jsonData.title}
						</h1>
						<h3 className="series-subtitle">
								{this.state.jsonData.subtitle}
						</h3>
						<h5>
		                <div style={{display:(this.state.jsonData.book_review? 'inline-block':'none')}}>

		                  <MenuBookIcon style={{fontSize:'30px'}}/>
		                  <span style={{margin:'0px 7px 0px 5px',color:"darkred"}}>Book Review by</span>
		                </div>

						{this.state.jsonData.authors.map(element => element.name).join(", ")}</h5>

						<div className="eri-series-body orthodoxy-and-culture eri-series-colors ">
			
							<img
			           		className="oac-main"
			           		src={this.imageUtils.getBannerImage(this.state.jsonData.series_id)}
			               	alt="" />
			               	<div className="series-quote">
			               		{this.state.jsonData.description}
							</div>
			               	<div className="speaker-bios">
								{this.state.speakerBios}
				             
			               	</div>

			               	<div className="sidebar mobile-only"> 
				               	<h2>
									{sidebarHeader}
								</h2>
								<div className="lectures-wrapper">
									{this.state.sidebarLectures}
								</div>
								<div>
									{this.state.resources}
								</div>
							</div>
			               	<div className="lecture-list">
			               		{this.state.lectureItems}
							</div>

						</div>
						<div className="sidebar non-mobile-only"> 
							<h2>
								{sidebarHeader}
							</h2>
							<div className="lectures-wrapper">
								{this.state.sidebarLectures}
							</div>
							<div >
								{this.state.resources}
							</div>
						</div>
				</main>
			</div>
		</div>
		)
	}
}

export default Series 

