import React from "react"
import Button from 'react-bootstrap/Button';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

 import {

  Link
} from "react-router-dom";
import logo from "../data/images/home/logo1.png"
function ERI (data) {
  window.scrollTo(0, 0);
  return (
      
      <main className="site-content eri-colors admin-directory">
        <h1>Register for 2021 Eagle River Institute</h1>
        <div className="admin-link-buttons">
        <Link className="back" to="/">
              <Button> <ArrowBackIosIcon/> Back </Button>
          </Link>
          <Link to="/admin/series" ><Button className="admin-link-button"><EditIcon/> Edit Media Library Entry</Button></Link>
          <Link to="/admin/series/new" ><Button className="admin-link-button"><AddCircleIcon/> Add Media Library Entry</Button></Link>
          <Link to="/admin/authors" ><Button className="admin-link-button"><EditIcon/> Edit Authors</Button></Link>
          <Link to="/admin/author/new" ><Button className="admin-link-button"><AddCircleIcon/> Add Authors</Button></Link>
          </div>
      </main>
    
  )
}

export default ERI 

 