import React, {Component} from "react"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import * as CatalogAPI from "../utils/CatalogAPI"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
 import {

  Link
} from "react-router-dom";
class AddSeries extends Component {
	constructor(props){
		super(props)
		window.scrollTo(0, 0);
		this.backText="< Back"
		this.passwordInput = React.createRef();
		this.state= {
			key:"",
			keyChangeable:true,
			title:"",
			authors:[0],
			description:"",
			"keywords":"",
			"all_authors":[],
			"series_id":-1,
			form_values:{
				key:this.props.serieskey,
				seriesData:{},
				authorsData:{},
				newAuthor:{},
				lecturesData:{
				},
				filesData:{}
			},
			form_files:{},
			lectures:[],
			newLectures:[],
			nextLectureId:0,
			checkingPassword:false,
			submitMessage:"none",
			displayDeleteModal:false,
			deleteChapter: {
				chapter_title:"",
				book_title:"",
				chapter_id:-1,
				click_target:""
			},
			hideLectures:[]

		}
		this.imageUtils = require("../utils/ImageUtils")

		this.images = this.imageUtils.getImagesFromDir("admin")
	}
	
	componentDidMount() { 
		let serieskey = this.props.serieskey
		if(serieskey !== 'new'){
		fetch(CatalogAPI.series_url(serieskey)).then(data => data.json()).then(data =>{
					this.setState({
						key:serieskey,
						keyChangeable:false,
						title:data.title,
						subtitle:data.subtitle,
						description:data.description,
						keywords:data.keywords,
						img:this.imageUtils.getBannerImage(data.series_id),
						authors:data.authors,
						id:data.series_id,
						post_url:CatalogAPI.series_update_url(data.series_id),
						series_id:data.series_id,
						lectures: data.lectures

					})
		})
	} else {
		this.setState({post_url:CatalogAPI.series_create_url})
	}
				console.log(CatalogAPI.authors_url)

		fetch(CatalogAPI.authors_url).then(data => data.json()).then(data =>{

					this.setState({
						all_authors:data.authors
					})
		})

	}
	
	checkPassword = (e) => {
		e.preventDefault()
		const formData = new FormData()

	    formData.append("password", this.state.password)
	    this.setState({checkingPassword:true})
		fetch(CatalogAPI.checkPassword(), {
	    method: 'POST', // *GET, POST, PUT, DELETE, etc.
	    mode: 'cors', // no-cors, *cors, same-origin
	    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
	    credentials: 'same-origin', // include, *same-origin, omit
	    headers: {
	      //'Content-Type': 'application/json'
	       //'Content-Type': 'multipart/form-data',
	    },
	    redirect: 'follow', // manual, *follow, error
	    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
	    body: formData // body data type must match "Content-Type" header
	  }).then( response => {
	    this.setState({checkingPassword:false})
	  		if(response.status >=400){
	  			this.setState({addShake:true})

	  			setTimeout(() => {  
	  				this.passwordInput.current.value=''
					this.setState({addShake:false})
					this.passwordInput.current.focus()
				}, 1000);
	  		} else {
	  			
	  				this.setState({submitMessage:"none","correctPassword":true})

	  			
	  		}


	  })
	}


	authorInput = (show,key) => {
		let clazzName =  (show == true ? "not-hidden":"hidden")
		clazzName = "authorBlock " + clazzName
		return (	
			<div className={clazzName} key={key}>
				 <Form.Group controlId="authorName">
				    <Form.Label>Name</Form.Label>
				    <Form.Control type="text" placeholder="Full Name..." />
				  </Form.Group>
				  <Form.Group controlId="authorShortName">
				    <Form.Label>Table Of Contents Name or Blank</Form.Label>
				    <Form.Control type="text" placeholder="e.g. Demacopoulos..." />
				  </Form.Group>
				  <Form.Group controlId="authorLongName">
				    <Form.Label>Author Long Name</Form.Label>
				    <Form.Control type="text" placeholder="e.g. George Demacopoulos, PhD" />
				  </Form.Group>
			</div>
				  )
	}

	getAuthorsOption = (formKey, defaultValue,controlId) =>{
		return (
			  <Form.Group key={formKey} controlId={controlId}>
			    <Form.Label>Author</Form.Label>
			    <Form.Control value={defaultValue} as="select" onChange={(event) => this.handleAuthorChange(event,formKey)}>
			    	<option value="-1"></option>
			    	{this.state.all_authors.map((element,key) =><option value={element.author_id} key={key}>{element.name}</option>)}
			    	<option value="-99">Newly Created Author</option>

			    </Form.Control>
			  </Form.Group>
  		)
	}
	removeAuthorField = () => {
		let authors = this.state.authors;
		authors.pop();
		this.setState({authors:authors})

	}
	addAuthorField =() => {
		this.setState({authors:this.state.authors.concat(0)})
	}


	handleNewAuthorChange = (event, key) => {

		let newState = this.state.form_values
		let newAuthor = newState.newAuthor

		newAuthor[event['target']['id']] = event.target.value
		newState['newAuthor'] = newAuthor
		this.setState({"form_values":newState})
	}


	handleAuthorChange = (event, key) => {

		let authorsValue = this.state.authors
		authorsValue[key] = this.state.all_authors.find(element => element.author_id  == event.target.value)
		let newState = {}
		newState["authors"] = authorsValue;
		this.setState(newState)
	}
	handleFileChange = (event) => { 
		console.log(event.target.files)
		const newState = this.state.form_files
		newState[event['target']['id']] = event.target.files
		this.setState({"form_files":newState})
	}

	handleChange = (event) => {
		let newState = this.state.form_values
		let seriesData = newState.seriesData

		seriesData[event['target']['id']] = event.target.value
		newState['seriesData'] = seriesData
		this.setState({"form_values":newState})
	}

	handleLectureChange= (event) =>{
		console.log(event)
		const lecture_id = event['target'].getAttribute("lecture_id")

		let newState = this.state.form_values
		let lecturesData = newState.lecturesData
		let lectureData;
		if(lecturesData.hasOwnProperty(lecture_id)){
			lectureData = lecturesData[lecture_id];
			console.log("getting old lecture:")
			console.log(lectureData)
		} else {
			lectureData = {}
			console.log('Making new lecture')
		}
		var val;
		if(event.target.hasOwnProperty('checked')){
			val = event.target.checked
		} else {
			val = event.target.value
		}
		lectureData[event['target']['id']] = val
		lecturesData[lecture_id] = lectureData
		newState['lectureData'] = lecturesData
		this.setState({"form_values":newState})

	}
	
	 
	handleSubmit = (e) => {
		e.preventDefault()

 		let data = this.state.form_values
		
		let authors =  this.state.authors.map(element => element.author_id)

		const formData = new FormData()
        formData.append("password",this.state.password)                     
		if(Object.keys(data.seriesData).includes("keywords")){
			formData.append("keywords", JSON.stringify(data.seriesData.keywords))
			delete data.seriesData['keywords']
		}	
		formData.append("newAuthor", JSON.stringify(this.state.form_values.newAuthor))
		formData.append("series", JSON.stringify(data.seriesData))
		formData.append("authors", JSON.stringify(authors))

		formData.append("lectures", JSON.stringify(data.lecturesData))
		   for (const property in this.state.form_files){
		   		formData.append(property, this.state.form_files[property]['0'])
		   }


		  const response =  fetch(this.state.post_url, {
			    method: 'POST', // *GET, POST, PUT, DELETE, etc.
			    mode: 'cors', // no-cors, *cors, same-origin
			    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			    credentials: 'same-origin', // include, *same-origin, omit
			    headers: {
			      //'Content-Type': 'application/text'
			      // 'Content-Type': 'application/x-www-form-urlencoded',
			    },
			    redirect: 'follow', // manual, *follow, error
			    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
			    body: formData // body data type must match "Content-Type" header
		  	}).then( response => {
		        if(response.status >=400){
		          this.setState({submitMessage:"Error!"})
		        } else {
		          this.setState({submitMessage:"Saved!"})

		          if(this.state.addChapter){

		          } else {
		            setTimeout(() => {  
		              this.setState({submitMessage:"Saved, lets see it!"})
		              window.location.href ="/series/"+this.state.key
		            }, 2000);

		          }



		        }


    });
		  

	}
	getLectureJSX = (element, key) => {
		if (element.lecture_id){
			key = element.lecture_id
		} else {
			let nextLectureId = this.state.nextLectureId

			key="new-"+(nextLectureId)
		}
		let iframes = ""
		let media_hosts = "";
		let media_types = "";
		if ("iframes" in element && element.iframes != null && element.iframes[0] != null){
			iframes = element.iframes.map(id => id.split("$$")[1]).join(" , ")
			media_hosts = element.iframes.map(id => id.split("$$")[2]).join(" , ")
			media_types = element.iframes.map(id => id.split("$$")[3]).join(" , ")
		}
		let attr = {'lecture_id':key}
		console.log(element)
		let lectureClazzName = "lecture-key"+key
		console.log(this.state.hideLectures)

		return (
			<li class={this.state.hideLectures.includes(key) ? 'hide':lectureClazzName}>
				  <Form.Group controlId="lecture-title" id="lecture-title" >
				    <Form.Label>Title</Form.Label>
				    <Form.Control defaultValue={element.title}  type="text" placeholder="" onChange={this.handleLectureChange} {...attr}/>
				  </Form.Group>	
				  <Form.Group controlId="lecture-author_id">
				    <Form.Label>Author</Form.Label>
				    <Form.Control value={element.author_id} as="select" onChange={this.handleLectureChange} {...attr}>
				    	<option value="-1"></option>
				    	{this.state.all_authors.map((element,key) =><option value={element.author_id} key={key}>{element.name}</option>)}
				   					    	<option value="-99">Newly Created Author</option>
 
				    </Form.Control>
				  </Form.Group>
				  <Form.Group controlId="lecture-description" id="lecture-desc">
				    <Form.Label>Description</Form.Label>
				    <Form.Control defaultValue={element.description}  as="textarea" placeholder="" onChange={this.handleLectureChange} {...attr}/>
				  </Form.Group>	
				  <Form.Group controlId="foreign_ids" id="foreign_ids">
				    <Form.Label>Foreign Ids</Form.Label>
				    <Form.Control defaultValue={iframes}  type="text" placeholder=": seperated" onChange={this.handleLectureChange} {...attr}/>
				  </Form.Group>	
				  <Form.Group controlId="media_types" id="media_types">
				    <Form.Label>Media Types</Form.Label>
				    <Form.Control defaultValue={media_types}  type="text" placeholder="paper, podcast, video; comma seperated" onChange={this.handleLectureChange} {...attr}/>
				  </Form.Group>

				  <Form.Group controlId="media_hosts" id="media_hosts">
				    <Form.Label>Media Host</Form.Label>
				    <Form.Control defaultValue={media_hosts}  type="text" placeholder="podbean, youtube, local; comma seperated" onChange={this.handleLectureChange} {...attr}/>
				  </Form.Group>
				<Form.Group controlId="source_id" className="media-form-group">
                  <Form.Label>Source</Form.Label>
                  <Form.Control as="select" onChange={this.handleLectureChange} >
                    <option value="">None</option>
                        <option value="0">Eagle River Institute</option>
                        <option value="1">Orthodox Christian Studies Center</option>
                        <option value="2">IOTA</option>
                  </Form.Control>
                  </Form.Group> 
				  <Form.Group className="banner-thumbnail-wrapper">
				  	<Form.Label>Local File</Form.Label>
				  	{element.file_name}
				    <Form.File id={"local_lecture_file-"+key}  onChange={this.handleFileChange}  {...attr}/>
				  </Form.Group>
				  <Form.Group  id="lecture-book_review" controlId="lecture-book_review" className={"iframe-wrapper-"+key}>
                    <Form.Label style={{marginTop:'20px'}}>Book Review</Form.Label>
                    <Form.Check defaultValue={element.book_review} defaultChecked={element.book_review} onChange={this.handleLectureChange} {...attr}/>
                  </Form.Group>
				  <Button id="delete-chapter" onClick={(e) => this.openDeletePopup(element.lecture_id, element.title, this.state.title, key)}><DeleteForeverIcon /> Delete Chapter</Button>
			</li>
			)
	}
	openDeletePopup = (lecture_id,chapter_title,book_title, key) => {
		console.log("!!!! "+key)
		let delete_object = {
			chapter_id:lecture_id,
			chapter_title:chapter_title,
			book_title:book_title,
			click_target:key
		}
		this.setState(
			{
				displayDeleteModal:true,
				deleteChapter:delete_object
			}
		)
	}
	addLectureField = (element) => {

		let newLectures = this.state.newLectures
		newLectures = newLectures.concat(this.getLectureJSX({},this.state.nextLectureId))
		this.setState({"nextLectureId":this.state.nextLectureId+1})

		this.setState({newLectures:newLectures})		
	}

	hideDeleteModal = () => {
		let delete_object = {
			chapter_id:-1,
			chapter_title:"",
			book_title:"",
			click_target:""
		}
		this.setState(
			{
				displayDeleteModal:false,
				deleteChapter:delete_object
			}
		)
	}
	foreverDeleteChapter = (chapter_id, click_target) => {

		console.log(this.state.deleteChapter)
		const formData = new FormData()
	    formData.append("chapter_id", chapter_id)
	    formData.append("password", this.state.password)
		fetch(CatalogAPI.deleteChapter(), {
		    method: 'POST', // *GET, POST, PUT, DELETE, etc.
		    mode: 'cors', // no-cors, *cors, same-origin
		    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
		    credentials: 'same-origin', // include, *same-origin, omit
		    headers: {
		      //'Content-Type': 'application/json'
		       //'Content-Type': 'multipart/form-data',
		    },
		    redirect: 'follow', // manual, *follow, error
		    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
		    body: formData // body data type must match "Content-Type" header
	  		}
	  	)
	 	let hideLectures = this.state.hideLectures
	 	console.log(hideLectures)
	 	console.log(click_target)
	 	hideLectures.push(click_target)
	 	console.log(hideLectures)

	 	this.setState({hideLectures:hideLectures})
	  	this.hideDeleteModal();
	}
	render() {
	
	let img, catalogImg = undefined;

	if(this.state.series_id >= 0){
		img = <img className="banner-thumbnail" src={this.imageUtils.getBannerImage(this.state.series_id)} alt="" />
		catalogImg = <img className="catalog-image-thumbnail" src={this.imageUtils.getCatalogImage(this.state.series_id)} alt="" />
	}

	let authors = this.state.authors.map((element,key) => this.getAuthorsOption(key, element.author_id,"author"))

	let lectures = this.state.lectures.map((element, key) => this.getLectureJSX(element,key))

	let keywords = this.state.keywords

	if(keywords !=  ""){
		keywords = keywords.join(" , ")
	}
    let submitShow = this.state.submitMessage == "none" ? "hidden ":"submit-message-bar ";
    let submitResultClass = this.state.submitMessage == "success" ? "red":"green"
    let submitMessageBarClass = submitShow+submitResultClass

	return (
		<div className="admin">
			<div className={submitMessageBarClass}><span>{this.state.submitMessage}</span></div>
		
		<div className={this.state.displayDeleteModal ?  'password-wrapper':'password-wrapper hidden'}>
		        <div className="password">
		        <h2>Confirm Chapter Removal</h2>
		        <div style={{fontSize:"16px"}}>
		        	Are you sure you want to remove chapter <b>'{this.state.deleteChapter.chapter_title}'</b> from book <b>'{this.state.deleteChapter.book_title}'</b>
		        	<br/>
		        	This action cannot be undone.
		        </div>
		        <br/>
		          <div type="submit" onClick={() => this.foreverDeleteChapter(this.state.deleteChapter.chapter_id, this.state.deleteChapter.click_target)}id="delete-chapter" className="submit-delete" value="Submit"> <DeleteForeverIcon /> Delete Forever </div>
		          <div className="cancel-password" style={{marginLeft: "0px",backgroundColor:"#ddd"}} onClick={() => this.hideDeleteModal()}>Cancel</div>
		      </div>
	      </div>


	      <div className={this.state.correctPassword ?  'password-wrapper hidden':'password-wrapper'}>
		      <form className="password" autocomplete="on" onSubmit={(e)=>this.checkPassword(e)}>
		        <h2>Authentication Required</h2>
		        <input name="username" type="text" className="hidden" id="username" autocomplete="on"/>
		        <input 
		        	id="password" 
		        	autocomplete="on" 
		        	name="password" 
		      	 	style={this.state.addShake ? {"animation": "shake 0.5s",boxShadow: "0 0 0.5em red"}:{}} 
		      	 	ref={this.passwordInput} 
		        	type="password" 
		        	placeholder="Password..." 
		        	onChange={(e) => {this.setState({password:e.target.value})}} 
		        	autofocus="true"
		        	/>
		        <img className={this.state.checkingPassword ? "thinking-wheel":"hidden"} src={this.images['thinking']} />

		        <br/>
		          <button type="submit" className="submit-password" value="Submit"> Submit </button>
		          <Link className="cancel-password" to="/admin">Cancel</Link>
		      </form>
	      </div>
			<main className="site-content eri-colors add-series">
				<h1>Add Lecture Series</h1>
				<Form onSubmit={this.handleSubmit}>
			      <Form.Group readOnly={true} className="hidden-series-id" controlId="formSeriesId">
				    <Form.Control defaultValue={this.state.id} type="text" placeholder="" />
				  </Form.Group>
			      <Form.Group controlId="title">
				    <Form.Label>Title</Form.Label>
				    <Form.Control defaultValue={this.state.title} type="text" placeholder="" onChange={this.handleChange}/>
				  </Form.Group>
			      <Form.Group controlId="subtitle">
				    <Form.Label>Subtitle</Form.Label>
				    <Form.Control defaultValue={this.state.subtitle} type="text" placeholder="" onChange={this.handleChange}/>
				  </Form.Group>
			      <Form.Group controlId="key">
				    <Form.Label>Key</Form.Label>
				    <Form.Control readOnly={!this.state.keyChangeable} defaultValue={this.state.key}  type="text" placeholder="All lower case no punctuation identifier" onChange={this.handleChange}/>
				  </Form.Group>	

			      <Form.Group controlId="description">
				    <Form.Label>Description</Form.Label>
				    <Form.Control className="series-description" defaultValue={this.state.description}  as="textarea"  onChange={this.handleChange}/>
				  </Form.Group>	
				<Form.Group controlId="keywords">
				    <Form.Label>Keywords</Form.Label>
				    <Form.Control defaultValue={keywords}  type="text" placeholder="Keywords seperated by a space or comma" onChange={this.handleChange}/>
				  </Form.Group>
				  
				  <Form.Group className="catalog-thumbnail-wrapper">
				  	<Form.Label>Change / Add Catalog Image</Form.Label>
				  	{catalogImg}
				    <Form.File id="catalogImg"  onChange={this.handleFileChange} />
				  </Form.Group>

				  <Form.Group className="banner-thumbnail-wrapper">
				  	<Form.Label>Change / Add Banner Image</Form.Label>
				  	{img}
				    <Form.File id="bannerImg"  onChange={this.handleFileChange} />
				  </Form.Group>

				<div className="lectures-list">
					<h2>Lectures</h2>
					<ol>
					{lectures}
					{this.state.newLectures}
					</ol>
					<Button onClick={() => this.addLectureField()}>Add Lecture Input</Button>

				</div>

				  <br/>
				  <br/>
				  <div className="btns-wrapper">
				  <Button style={{width:"inherit"}} type="submit" className="lecture-btn-fake">
	                <div style={{borderRadius:"5px",display:"inline-block",padding:"5px", width:"200px"}} className="save-button" onClick={() => {this.setState({addChapter:false})}}>Save and Exit</div>
	              </Button>

				  <Link to="/admin" className="cancel-link"><Button variant="primary" className="cancel-button">Cancel</Button></Link>
				</div>
				</Form>
			</main>
			</div>
		
	)
	}
}

export default AddSeries 

//				  

