import React, {Component} from "react"

import Modal from "react-bootstrap/Modal"
import Button from 'react-bootstrap/Button';

import ERIBrochure from "../data/pdf/ERI_Brochure.pdf"

import ERIArchive from "../data/pdf/ERI_Archive.pdf"
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import data from "../data/json/summer-session.json"

import "../styles/eri-series.css"
import "../styles/summer-session.css"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";



import ERISchedule from "../data/pdf/ERI_Schedule.pdf"
import { Document, Page } from 'react-pdf';
import { Container, addScrollHandler, removeScrollHandler } from 'scroll-listener';
let lastScrollY = 0;
let ticking = false;
class SpecialLectureSeries extends Component{


  constructor(props){
    super(props)
      window.scrollTo(0, 0);
    this.state={
      showModal: false,
      modalText:"",
      highlightedID:"theme",
      navs:[],
      elementIds:['theme', 'speakers','jillions','ladouceur', 'frmarc', 'schedule', 'brochure', 'previous','register', 'pilgrimage']
    }
    
    const imageUtils = require("../utils/ImageUtils")

    this.images = imageUtils.getImagesFromDir("summer-session")  
    this.pilgImages = imageUtils.getImagesFromDir("summer-session/pilgrimage")  
    this.data = data
  }
  

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
    this.setState({navs:[React.createRef(),React.createRef(),React.createRef(),React.createRef(),React.createRef(),React.createRef(),React.createRef(),React.createRef(),React.createRef(),React.createRef(),React.createRef()]})

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  //nav = React.createRef()
  
  handleScroll = () => {
    lastScrollY = window.scrollY;

    if (!ticking) {
      window.requestAnimationFrame(() => {
        for(const nav in this.state.navs){
          this.setupPositionCheck(this.state.navs[nav],this.state.elementIds[nav])
        }
        ticking = false;
      });
   
      ticking = true;
    }
  };
  setupPositionCheck = (element, id) => {
    try{
        let absPos = element.current.offsetTop-lastScrollY
        if(absPos < 5 && absPos > -1*element.current.offsetHeight){
          this.setState({"highlightedID":id})
        }
      } catch(e){
      } 
  }
  buildSidebar = (data, clazz,id) =>{
    let elementIds = []
    let enteries = data.map((item,key,id) => {
      let subItem = "";
      if(item.hasOwnProperty("subItem")){
        subItem = <ul>{this.buildSidebar(item.subItem,'sub-item',item.id)}</ul>
      }
      let href = "#"+item.id
      return  (
      <li key={key} style={{background:this.state.highlightedID == item.id ? "#333":"#ccc"}}>
          <a href={href} className={clazz} style={{color:this.state.highlightedID == item.id ? "#ccc":"#333"}}>{item.title}</a>
          {subItem}
        </li> 
        )})
    let jsx = <ul>{enteries}</ul>
    return jsx
  };
  buildSpeakers = (data) => {
    let enteries = data.map((item,key) => {
          let lectures = item.lectures.map((lecture,key2) => {
            return <li key={key2} dangerouslySetInnerHTML={{ __html: lecture }}></li>
          })
          let href = "#"+item.id
          let clazz ="speaker speaker-"+key

          return  (
            <div key={key} className={clazz} id={item.id} ref={this.state.navs[2+key]}>
            <div className="lectures">
                 <h4 dangerouslySetInnerHTML={{ __html: item.topic }}></h4>
                 <h4 style={{fontSize:"25px"}}>{item.name}</h4>
                  <ol style={{textAlign:"left"}}>{lectures}</ol>
                 </div>
              <div className="speaker-info">

                 <div className="speaker-img-wrapper">
                  <span>{item.name}</span>
                  <img className="speaker-img" src={this.images[item.img]} />
                 </div>
                 <div className="bio">
                  {item.bio}
                 </div>
                  </div>

                 <div className="bio" style={{padding:"20px", width:"calc(100% - 40px)"}} dangerouslySetInnerHTML={{ __html: item.description }}>
                 
                 </div>
                 
                 </div>
            )})
        let jsx = <div className="speaker-info-wrapper">{enteries}</div>
        return jsx


   
  }

  render(){
    console.log(this.images)
    //let handler = () => { alert('hi'); };
    //addScrollHandler(Container, handler);
    let tocJsx = this.buildSidebar(this.data.toc, 'main-item')
    let speakersJsx = this.buildSpeakers(this.data.speakers)
    return (
      <div>

      <div className="summer-session" >
        <div className="toc-sidebar" >
        <span style={{marginLeft:"20px",fontSize:"22px",color:"blue"}}>Attend</span>
          {tocJsx}
        </div>

        <div className="info-area-wrapper">
          <div className="info-area">
            <div id="theme" className="info-block" ref={this.state.navs[0]}>
              <h1>August 1-5, 2024</h1>
              <div className="theme-blob">
                <img className="aerial" src={this.images['hp']} />
                <div className="theme-blurb">
                The Eagle River Institute began in 1995. Sponsored by Saint John Orthodox Cathedral, it is held each summer August 1 -5, and offers twelve hours of instruction in a variety of Orthodox topics, including Theology, History, Liturgy, Spirituality, and Scriptural Studies. Our goal is to provide a forum for Orthodox scholars to address lay people on topics relevant to everyday, Christian life.<br/><br/>
                The Institute will also seek to contribute to the greater, educational needs of the Church by publishing its lectures in various media formats.
                </div>

                </div>
                 <div style={{fontSize: "20px",marginTop: "10px"}}>
                <b>Lodging:</b> A limited number of guest rooms are available on the Cathedral grounds. Some lodging may also be available in the homes of parishioners. For more information please inquire by email, stjohnalaska@aol.com, or by phone at 907-696-2002.
                <br/><br/>
                <b>Meals:</b> Saint John’s parishioners will provide snacks during breaks and a hearty, fasting meal between sessions for all participants.
                </div>
              </div>
              <div id="speakers" className="info-block">
                  <h1  ref={this.state.navs[1]}>2024 Speakers & Topics</h1>
                  {speakersJsx}
              </div>
              <div id="frmarc" className="info-block" ref={this.state.navs[4]}>
                <h1>A Word from Fr. Marc Dunaway, Pastor of St. John's</h1>
                                <img className="dunaways" src={this.images['dunaways']} />
The Eagle River Institute is held each summer on the fixed dates of August 1-5, culminating in the celebration of the Feast of the Transfiguration on August 6. The first series of presentations this year is by Fr. John Jillions. He will challenge us to think about how America has seen itself in relation to the Divine will and how this squares with Orthodox spirituality and the calling of the Church. Fr. John will also give a fourth, special lecture on a subject he has considered and written about recently: What aspects of our Orthodox tradition can change and how does that change take place? I am looking forward to both of these topics as serious and relevant matters to all Christians today. The second theme by Dr. Paul Ladouceur will look at some key components in Orthodox spirituality over the centuries, and draw from these practical guidance for the spiritual needs of Orthodox Christians in the modern world. Dr. Paul is also an avid backpacker and plans to spend time after the Institute exploring some local hiking trails in Alaska, so I look forward to visiting more with him at that time too, if I can keep up. One of the most enjoyable parts of our week together during the Institute is the opportunity to visit informally with our presenters. We love to have guests join us for this Institute. I hope you will think about it this summer. You can learn more about our parish, Saint John Orthodox Cathedral, on the Cathedral website:<a href="https://stjohnalaska.org" target="_blank">stjohnalaska.org</a>. Please call or write, if you have any questions.
              
                
              </div>
              <div id="schedule" ref={this.state.navs[5]}>
                              <h1>2024 Eagle River Institute Schedule</h1>
                <img className="schedule-img" style={{maxWidth:"500px"}} src={this.images['ERI_Schedule']} />

                                  <Link className="schedule-link" to="summer-session/schedule" target="_blank">
                                  <PictureAsPdfIcon style={{marginRight:"5px"}}/>
                                   Download Schedule
                                  </Link>


              </div>
              <div id="brochure" ref={this.state.navs[6]}>
                              <h1>2024 Eagle River Institute Brochure</h1>
                <img className="schedule-img" style={{maxWidth:"800px"}} src={this.images['ERI_Brochure_1']} />
                <img className="schedule-img" style={{maxWidth:"800px"}} src={this.images['ERI_Brochure_2']} />
       <Link className="schedule-link" to="summer-session/brochure" target="_blank">
                                 <PictureAsPdfIcon style={{marginRight:"5px"}}/>Download  Brochure</Link>
                                

              </div>
              
              <div id="previous" ref={this.state.navs[7]}>
                <h1>Previous Speakers</h1>
                <a className="schedule-link" href={ERIArchive} target="_blank"><PictureAsPdfIcon style={{marginRight:"5px"}}/>Speakers From Previous Years</a>

              </div>             
              <div id="register"ref={this.state.navs[8]}>
                <h1>Register for 2024 Eagle River Institute</h1>
                 <Link className="schedule-link" to="summer-session/register" target="_blank">Register</Link>
              </div>
              <div id="pilgrimage" style={{maxWidth:"1000px",margin:"0 auto"}} ref={this.state.navs[9]}>
                              <h1>Annual Pilgrimage to Kodiak and Spruce Island <br/> August 7-9</h1>
                                              <img className="pilgrImage" src={this.images['pilgrimage']} />

                              <div style={{minHeight:"190px", fontSize:"20px"}}>
                                This pilgrimage is hosted annually on August 7-9 by the Orthodox Church in America, Alaska Diocese, to celebrate the anniversary of the canonization of Saint Herman of Alaska. This pilgrimage is a great addition to the Eagle River Institute. My wife and I took part in 2021 and were moved by the beauty and quietness of Spruce Island. I encourage you to participate, if you can. Generally pilgrims arrive in Kodiak sometime on August 7 and depart on August 10. Overnight accommodations can be arranged in local hotels and B&B's. The Cathedral in Kodiak graciously provides several meals and arranges transportation to Spuce Island. A modest donation and prior registration is requested. More information can be found at the OCA website link below.
                              </div>
                                <a className="schedule-link" href="https://www.oca.org/history-archives/saint-herman-pilgrimage-alaska-tradition" target="_blank">Visit Pilgrimage Website</a>

                              <img className="pilgrimage-img" src={this.images["1 - Kodiak Cathedral"]} />
                              <img className="pilgrimage-img" src={this.images["2- Metropolitan Tikhon 2021"]}/>
                              <img className="pilgrimage-img" src={this.images["3 - Boat ride from Kodiak to Spruce"]}/>
                              <img className="pilgrimage-img" src={this.images["4 - Beach on Spruce Island"]}/>
                              <img className="pilgrimage-img" src={this.images["5 - Chapel on Spruce Island"]}/>
                              <img className="pilgrimage-img" src={this.images["6 - Icon along the Path"]}/>




              </div> 
            </div>
          </div>
        </div>
        </div>
      
    )
  
  }
}
export default SpecialLectureSeries

// <Link className="schedule-link" to="summer-session/brochure" target="_blank">
//                                 <PictureAsPdfIcon style={{marginRight:"5px"}}/>Download  Brochure</Link>


                                 // <Link className="schedule-link" to="summer-session/schedule" target="_blank">
                                 // <PictureAsPdfIcon style={{marginRight:"5px"}}/>
                                 //  Download Schedule
                                 //  </Link>


      // <div className="register-pop-up-wrapper" >
      //   <div className="link-wrapper">
      //   <a href="/series" target="_blank" className="register-pop-up" >
      //     <div style={{fontWeight:"bold", padding:"15px 20px", marginTop: "160px"}}>&gt; Listen Now</div>
      //   </a>
      //   </div>
      // </div>

      // <ul>
      //   <li>
      //     <a class="main-item">2022 Institute</a>
      //   </li>
      //   <li>
      //   <a className="main-item"></a>
      //     <ul>
      //       <li><a class="sub-item">Speakers</a></li>
      //       <li><a class="sub-item">Topics</a></li>
      //       <li><a class="sub-item">Schedule</a></li>
      //       <li><a class="sub-item">Brochure</a></li>
      //       <li><a class="sub-item">Register</a></li>
      //     </ul>
      //   </li>
      //   <li>
      //     <a class="main-item">Pilgrimage</a>
      //   </li>
      //    <li>
      //     <a class="main-item">Previous Speakers</a>
      //   </li>
      //   <li>
      //     <a class="main-item">A Word From Father Marc</a>
      //   </li>
      // </ul>