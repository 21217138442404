import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import "./styles/eri-series.css"
import "./styles/global_media.css"

import SummerSession from "./pages/SummerSession.js"
import DownloadRedirect from "./static_downloads/DownloadRedirect.js"


import Test from "./pages/Test.js"
import Catalog from "./pages/Catalog.js"
import Series from "./pages/Series.js"
import Home from "./pages/Home.js"
import Register from "./pages/Register.js"
import EditSeries from "./admin/EditSeries.js"
import ViewSeries from "./admin/ViewSeries.js"
import CreateSeries from "./admin/CreateSeries.js"
import Admin from "./admin/Admin.js"

import ViewAuthors from "./admin/ViewAuthors.js"
import EditAuthor from "./admin/EditAuthor.js"


import Helmet from "./utils/ReactHelmet.js"

import logo from "./data/images/home/logo1.png"
import HttpsRedirect from 'react-https-redirect'

import ReactGA from 'react-ga';

require('dotenv').config()


export default function App() {

  let style = {}

  console.log(process.env.REACT_APP_BANNER)

  if (process.env.REACT_APP_BANNER == "false"){
    style = {display:"none"}
  }

  return (
    <HttpsRedirect>
    <Helmet />
    <Router>
      <div style={style}>
           <Link to="/">
           <div className="invert">
              <img className="logo" src={logo} />
            </div>
            <div className="header" >Eagle River Institute</div>
            <div className="subheader">of Orthodox Christian Studies<br/>

            </div>
            </Link>
              <nav  className="menu">
                <ul>
                 
                  <li>
                    <Link to="/summer-session">ATTEND</Link>
                  </li>
                  <li>
                    <Link to="/series">LIBRARY</Link>
                    </li>
                  <li>
                    <a href="/#our-story">ABOUT</a>
                  </li>
                </ul>
              </nav>
          </div>
          <Switch>

            <Route path="/test" children={<Test />}/>

            <Route path="/admin/series/new" children={<CreateSeriesWrapper />} />
            <Route path="/admin/series/:serieskey" children={<EditSeriesWrapper />} />
            <Route path="/admin/series" children={<ViewSeries />} />


            <Route path="/admin/author/:authorid" children={<EditAuthorWrapper />} />
            <Route path="/admin/authors" children={<ViewAuthors />} />

            <Route path="/admin" children={<Admin/>} />



            <Route path="/summer-session/register" children={<Register/>}/>

            <Route path="/summer-session/:to" children={<DownloadRedirectWrapper/>} /> 

            <Route path="/summer-session" children={<SummerSession/>} /> 

            <Route path="/series/:serieskey" children={<DisplaySeries/>} />


            <Route path="/series/:serieskey" children={<DisplaySeries/>} />
            <Route path="/series" children={<Catalog />} />
            <Route path="/" children={<Home />} />
          </Switch>
          <div className="footer">
            <div>Phone: (907) 696-2002 </div>
            <div>Email: eagleriverinstitute@gmail.com</div>
            <div>18936 Monastery Drive Eagle River, Alaska</div>
          </div>
    </Router>
    </HttpsRedirect>
  );
}




function DownloadRedirectWrapper(){
    let { to } = useParams();
  return <DownloadRedirect to={to}/>
}
function EditAuthorWrapper() {
  let { authorid } = useParams();
  return <EditAuthor authorid={authorid}/>
}
function CreateSeriesWrapper() {
  return <CreateSeries/>
}
function EditSeriesWrapper() {
  let { serieskey } = useParams();
  return <EditSeries serieskey={serieskey}/>
}

function SummerSesion() {
  return <SummerSession/>
}

function DisplaySeries(path) {
  let { serieskey } = useParams();

  if(['psalm-137','forgiveness-sunday'].indexOf(serieskey) >=0){
    window.location.href = '/series/scholars-preach'
  }
  console.log(serieskey)

  return <Series serieskey={serieskey}/>

}

function About() {
  return <h2>About</h2>;
}

function Users() {
  return <h2>Users</h2>;
}
