import React, {Component} from "react"

import "../styles/home.css"

 import {
  Link
} from "react-router-dom";
import ERIArchive from "../data/pdf/ERI_Archive.pdf"
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
class ERI extends Component{
  constructor(props){
		super(props)
			window.scrollTo(0, 0);

		const imageUtils = require("../utils/ImageUtils")

		this.images = imageUtils.getImagesFromDir("home")
	}
	
	render(){
	return (
		<div className="home">
	         
			<div className="site body">
				<main className="site-content home">

					<div className="home-banner-wrapper">
						<img className="home-banner" src={this.images['Pano']} />
					</div>
					
					<div className="lower-block">
						<div className="three-column-wrapper">
							<Link to="/summer-session" className="three-column three-column-one">
								<div className="column-content">
								<div className="mat-icon-wrapper">
									<img className="mat-icon cld" src={this.images['SummerSession']} />
								</div>	
									<h3>
										<span className="caps">ATTEND</span>
										<br/>
										Summer Session Aug, 1-5
									</h3>
								</div>
							</Link>
							<Link to="/series" className="three-column three-column-two">
								<div className="column-content">
									<div className="mat-icon-wrapper">

										<img className="mat-icon " src={this.images['explore']} />
									</div>
									<h3>
									<span className="caps">LIBRARY</span>
									<br/>
										Podcasts, Videos, Articles
									</h3>

								</div>
							</Link>
							<a href="/#our-story" className="three-column three-column-three">
								<div className="column-content">
									<div className="mat-icon-wrapper">

										<img className="mat-icon st-john" src={this.images['about']} />
									</div>
									<h3>
										<span className="caps">
											ABOUT
										</span>
										<br/>
										<div id="our-story"></div>

										Eagle River Institute

									</h3>
									</div>
							</a>
						</div>

					</div>
					<div className="about-wrapper" >


						<div className="about-item">
							<div className="flex-wrapper"> 
								<img className="chapel" src={this.images['chapel']} />
								<div className="chapel-text">
									<b>The Eagle River Institute</b> is hosted annually by the members of <a href="http://stjohnalaska.org" target="_blank">Saint John Cathedral</a> to provide a forum for Orthodox scholars to address lay people on topics relevant to everyday, Christian life.
									<br/><br/>
									The Institute will also seek to contribute to the greater, educational needs of the Church by publishing its lectures in various media formats.								
									<div className="link-wrapper">
										<a href="https://www.youtube.com/watch?v=m0RVkLoP8to" target="_blank">Introduction to the ERI by Fr. Marc and Benjamin Dunaway</a>
									</div>
									<div className="link-wrapper">
										<a href={ERIArchive} target="_blank"><PictureAsPdfIcon style={{marginRight:"5px"}}/>Speakers From Previous Years</a>
									</div>
								</div>
							</div>  
						</div>
						<div className="about-item">
							<div className="flex-wrapper"> 
								<a href="https://iota-web.org" target="_blank">
									<img className="iota-logo" src={this.images['iota']} />
								</a>
								<div className="iota-text">
									The Eagle River Institute is a co-laborer with the <a href="https://iota-web.org" target="_blank"> <b>International Orthodox Theologial Association.</b></a>  Founded in 2017, IOTA is a community of scholars and professionals dedicated to the worldwide exchange of knowledge within the context of the Orthodox Tradition. At the 2023 conference in Volos, Greece the Eagle River Institute worked with IOTA scholars to produce 23 short videos called "IOTA Talks." These are short, engaging videos in which Orthodox scholars share their work with a non-academic audience. These IOTA Talks can be found in our <Link to="/series">Media Library.</Link>
									<div className="link-wrapper">
										<a href="https://www.youtube.com/watch?v=F964sfVIYOo" target="_blank">Presidential Address by Dr. Paul Gavrilyuk at IOTA Conference</a>
									</div>
								</div>
							</div>
						</div>
						<div className="about-item">
							<div className="flex-wrapper"> 
								<div className="st-johns-text">
								<a href="http://stjohnalaska.org" target="_blank"><b>Saint John’s community</b></a> sits at the base of the Chugach mountains in Eagle River, Alaska. It began as a ministry for young adults and eventually became part of the Orthodox Church.
									<br/><br/>
									We invite you to join us in our journey to know God and to serve our neighbors. Discover the difference in Orthodox Christianity.
									<div className="link-wrapper">
										<a href="http://stjohnalaska.org" target="_blank">Visit Saint John's Cathedral Website</a>
									</div>
								</div>
								<img className="cathedral" src={this.images['cathedral']} />

							</div>
						</div>

						<div className="photo-collection">
						<h2>Life In An Orthodox Community</h2>
							<div className="photos-wrapper">
								<div className="photo-wrapper">
									<img className="photo" src={this.images['1']} />
								</div>
								<div className="photo-wrapper">

								<img className="photo" src={this.images['2']} />
								</div>
								<div className="photo-wrapper">
									<img className="photo" src={this.images['3']} />
								</div>
								<div className="photo-wrapper">
									<img className="photo" src={this.images['4']} />
								</div>
								<div className="photo-wrapper">
									<img className="photo" src={this.images['5']} />
								</div>
								<div className="photo-wrapper">
									<img className="photo" src={this.images['6']} />
								</div>
								<div className="photo-wrapper">
									<img className="photo" src={this.images['7']} />
								</div>
								<div className="photo-wrapper">
									<img className="photo" src={this.images['8']} />
								</div>
								<div className="photo-wrapper">
									<img className="photo" src={this.images['9']} />
								</div>
							</div>
						</div>


					</div>
				</main>
			</div>
		</div>
		)
	}
}
export default ERI 

						// The Eagle River Institute is a forum for Orthodox scholars to address lay people on topics relevant to everyday, Christian life.
