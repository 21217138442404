import React from "react"
import Button from 'react-bootstrap/Button';

 import {

  Link
} from "react-router-dom";
import logo from "../data/images/home/logo1.png"
function ERI (data) {
	window.scrollTo(0, 0);
	const backText="< Back"
	return (
			
			<main className="site-content eri-colors register">
				<h1>Register for 2024 Eagle River Institute</h1>
				<Link className="back" to="/summer-session">
			        <Button> {backText} </Button>
			    </Link>
			    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeOdVIUJ7UC9lXFg9itbZPkpuhLi5sdI-vTM3DYBQrfz9Addg/viewform?embedded=true" width="100%" height="2100" overflow="hidden" frameBorder="0" marginHeight="0" marginWidth="0" style={{height:"2100px"}}>Loading…</iframe>

			</main>
		
	)
}

export default ERI 

//			    <h2 style={{color:"white", textAlign:"center"}}>Registration Comming Soon!</h2>
