
import * as PhotoserverAPI from "./PhotoserverAPI"

export const catalog_url =  process.env.REACT_APP_CATALOG_API_BASE_URL+"/api/catalog"

export const series_url = (serieskey) => {
	return process.env.REACT_APP_CATALOG_API_BASE_URL+"/api/series/"+serieskey
}

export const authors_url =  process.env.REACT_APP_CATALOG_API_BASE_URL+"/api/authors"

export const series_create_url =  process.env.REACT_APP_CATALOG_API_BASE_URL+"/api/series/create"

export const series_update_url = (serieskey) => {
	return process.env.REACT_APP_CATALOG_API_BASE_URL+"/api/series/"+serieskey+"/update"
}
export const author_create_url =  process.env.REACT_APP_CATALOG_API_BASE_URL+"/api/author/create"

export const chapter_create_url = process.env.REACT_APP_CATALOG_API_BASE_URL+"/api/chapter/create"

export const author_update_url  = (authorid) => {
	return process.env.REACT_APP_CATALOG_API_BASE_URL+"/api/author/"+authorid+"/update"
}

export const author_url = (authorid) => {
	return process.env.REACT_APP_CATALOG_API_BASE_URL+"/api/author/"+authorid
}
//export const image_url= process.env.REACT_APP_CATALOG_API_BASE_URL+"/static/images/"
export const image_url= process.env.REACT_APP_CATALOG_API_BASE_URL+"/static/images/"
export const papers_url= process.env.REACT_APP_CATALOG_API_BASE_URL+"/papers/"


export const getPaperURL = (lecture_id, filename) => {
	return PhotoserverAPI.get_non_image(lecture_id,filename)
}	

export const getSourceImage = (source_id) => {
	return process.env.REACT_APP_CATALOG_API_BASE_URL+"/api/source/image/"+source_id
}

export const checkPassword = () =>{
	return process.env.REACT_APP_CATALOG_API_BASE_URL+"/secure/authenticate"
}

export const deleteChapter = (chapter_id) => {
	return process.env.REACT_APP_CATALOG_API_BASE_URL+"/api/chapter/delete"

}