import React, {Component} from "react"

import ReactDOM from "react-dom";
import Button from 'react-bootstrap/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import "../styles/eri-series.css"
import "../styles/catalog.css"
import "../styles/admin.css"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import * as CatalogAPI from "../utils/CatalogAPI"


class SpecialLectureSeries extends Component{
  constructor(props){
    super(props)

console.log(CatalogAPI.catalog_url)

    this.images =  require("../utils/ImageUtils").getImagesFromDir("catalog")

    this.state ={
          filterValue: "",
          lectureVisible: Array().fill(true),
          authors:[],

    }

    }
  
  componentDidMount() { 
    let allKeywords = []

    fetch(
        CatalogAPI.authors_url
      ).then(res =>res.json()).then( data => {
        console.log(data)
        this.setState({
          authors:data.authors
        })
      })

  }

  setFilterValue = (value) => {
    this.setState({filterValue:value})   
  }

  checkFilterValue = (words) =>{
    console.log(words)
    console.log(this.state.filterValue)
    if(this.state.filterValue == '' ){
      return true;
    }
    let contains = this.state.filterValue.toLowerCase().split(" ").map(element => this.checkSingleWord(element, words))
    return contains[0].includes(true);
  }
  checkSingleWord = (filterWord, keywords) => {
    const contains = keywords.split(" ").map(word => word.toLowerCase().includes(filterWord))
    return contains
  }

  getAuthorJSX = (data,key) =>{

    const href = "/admin/author/"+data.author_id
      return(<li className={(this.checkFilterValue(data.name))? '':'hide'} key={key}>
          <Link  className="series-lecture" to={href}>

            <div className="lecture-metadata">
                <div to={href} className="lecture-title">
                {data.name}
                </div>

               
            </div>
           </Link>
      </li>)

  }
  changeSelectedKeyword =(key) => {
    let allKeywords = this.state.allKeywords;
    allKeywords[key].selected = !allKeywords[key].selected
    this.setState({"allKeywords":allKeywords})
  }
  getKeywordsJSX = (element,key) =>{
    return (
      <li className={this.state.allKeywords[key].selected == true ? "selected":"unselected"} key={key} onClick={() => this.changeSelectedKeyword(key)}>
        <Button>{element} <span>X</span></Button>
      </li>
      )
  }
  render(){
    console.log(this.state)
    const series = this.state.authors.map((element,key) => this.getAuthorJSX(element,key))

    return (
      <div className="admin">
      <div className="special-lecture-series">
      <h1>Admin</h1>
      <h2>Authors</h2>

      <div className="lecture-list-wrapper">

          <Link className="back" to="/admin">
              <Button> <ArrowBackIosIcon/> Back </Button>
          </Link>
        <div className="lecture-list-filter-wrapper">


          <input placeholder="Search Authors..." type="text" id="lecture-list-filter" onChange={(event) => this.setFilterValue(event.target.value)}/>
        </div>
          <div className="lists-wrapper">
        <ul className="series-lecture-list">
          {series}
        </ul>
      </div>
      </div>
    </div>
    </div>
    )
  
  }
}
export default SpecialLectureSeries

