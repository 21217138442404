import * as CatalogAPI from "./CatalogAPI"
import * as PhotoserverAPI from "./PhotoserverAPI"

export const getImagesFromDir = (lecture, id) => {
	const getImageNameFromModule = (element) =>{
		return element.default.substring(element.default.lastIndexOf("/")+1,element.default.indexOf("."))
	}
	let allImages = {}
	const importAll = (r) => {
		const mapped = r.keys().map(r).forEach(element =>  {
			allImages[getImageNameFromModule(element)] = element.default
		})
	}	

	  
	switch(lecture) {
		case('catalog'):
		    importAll(require.context("../data/images/catalog", false, /\.(png|jpe?g|svg)$/));
		case('home'):
		    importAll(require.context("../data/images/home", false, /\.(png|jpe?g|svg)$/));
		case('summer-session'):
		    importAll(require.context("../data/images/summer-session", false, /\.(png|jpe?g|svg)$/));
		case('admin'):
		    importAll(require.context("../data/images/admin", false, /\.(png|jpe?g|svg|gif)$/));

		default:
			break; 
	}
	return allImages
}

export const getAuthorImage = (id) => {
	return PhotoserverAPI.get_image("author",id)
}
export const getBannerImage = (key) => {
	if (key !== "new"){
		return PhotoserverAPI.get_image("banner",key)
	}
}

export const getCatalogImage = (key) => {
	if (key !== "new"){
	return PhotoserverAPI.get_image("catalog",key)
	}
}


