import React, {Component} from "react"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import * as CatalogAPI from "../utils/CatalogAPI"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
 import {

  Link
} from "react-router-dom";
class AddSeries extends Component {
  constructor(props){
    super(props)
    window.scrollTo(0, 0);
    this.backText="< Back"
    this.passwordInput = React.createRef();

    this.state= {
      slideIndex:-2,
      key:"",
      keyChangeable:true,
      title:"",
      authors:[0],
      description:"",
      "all_authors":[],
      "series_id":-1,
      form_values:{
        key:this.props.serieskey,
        seriesData:{},
        authorsData:{},
        newAuthor:{},
        lectureData:{},
        filesData:{},
        media_data:[]
      },
      form_files:{},
      media_files:{},
      submitMessage:"none",
      iframeJSXs:[0],
      checkingPassword:false
    }
    this.imageUtils = require("../utils/ImageUtils")
    this.images = this.imageUtils.getImagesFromDir("admin")

  }

 
  checkPassword = (e) => {
    e.preventDefault()
    const formData = new FormData()

      formData.append("password", this.state.password)
      this.setState({checkingPassword:true})

    fetch(CatalogAPI.checkPassword(), {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        //'Content-Type': 'application/json'
         //'Content-Type': 'multipart/form-data',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: formData // body data type must match "Content-Type" header
    }).then( response => {
            this.setState({checkingPassword:false})

        if(response.status >=400){
          this.setState({addShake:true})

          setTimeout(() => {  
          this.passwordInput.current.value=''
          this.setState({addShake:false})
          this.passwordInput.current.focus()

        }, 1000);
        } else {
            this.changeSlide(2)
            this.setState({submitMessage:"none","correctPassword":true})

        }

    })
  }

  

  authorInput = () => {

    return (  
      <div className={this.state.showCreateAuthor == true ? "series-new-author":"series-new-author"}>
        <h2>Create New Author</h2>
        <Form  onSubmit={this.handleAuthorSubmit}>
           <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Full Name..." onChange={this.handleNewAuthorChange} />
            </Form.Group>
            <Form.Group controlId="short_name">
              <Form.Label>Table Of Contents Name or Blank</Form.Label>
              <Form.Control type="text" placeholder="e.g. Demacopoulos..." onChange={this.handleNewAuthorChange}/>
            </Form.Group>
            <Form.Group controlId="long_name">
              <Form.Label>Author Long Name</Form.Label>
              <Form.Control type="text" placeholder="e.g. George Demacopoulos, PhD" onChange={this.handleNewAuthorChange}/>
            </Form.Group>
            <Form.Group controlId="biotext">
              <Form.Label>Author Biography Paragraph</Form.Label>
              <Form.Control as="textarea"  placeholder="" onChange={this.handleNewAuthorChange}/>

            </Form.Group>
            <Form.Group className="banner-thumbnail-wrapper">
              <Form.Label>Biography Image</Form.Label>
              <Form.File id="newAuthorBiographyImage" onChange={this.handleFileChange} />
            </Form.Group>
            <div className="question-btn-wrapper">

              <Button className="answer yes" type="submit"><span>Save and Continue</span></Button>
              <div className="answer no" onClick={() => this.changeSlide(-1)}><span>Cancel</span></div>
            </div>
            </Form>
        </div>
          )
  }
  handleAuthorSubmit = (e) => {
    e.preventDefault()

     const formData = new FormData()
     formData.append("password",this.state.password)
     formData.append("content", JSON.stringify(this.state.form_values.newAuthor))

     if ('new_author_image' in this.state) {
       formData.append("img", this.state.new_author_image)
     }

    const response =  fetch(CatalogAPI.author_create_url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        //'Content-Type': 'application/json'
         //'Content-Type': 'multipart/form-data',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: formData // body data type must match "Content-Type" header
    }).then( response => {
        if(response.status >=400){
          this.setState({submitMessage:"Error!"})
        } else {
           let state = this.state
           delete state.new_author_image
           this.setState(state)
              fetch(CatalogAPI.authors_url).then(data => data.json()).then(data =>{

                  this.setState({
                    all_authors:data.authors
                  })
            })
          this.setState({submitMessage:"Saved!"})
          setTimeout(() => {  this.changeSlide(1);  }, 1000);
        }


    })
  }
  getAuthorsOption = (formKey, defaultValue,controlId) =>{
    return (
        <Form.Group key={formKey} controlId={controlId}>
          <Form.Label>Author</Form.Label>
          <Form.Control value={defaultValue} as="select" onChange={(event) => this.handleAuthorChange(event,formKey)}>
            <option value="-1"></option>
            {this.state.all_authors.map((element,key) =><option value={element.author_id} key={key}>{element.name}</option>)}
          </Form.Control>
        </Form.Group>
      )
  }
  removeAuthorField = () => {
    let authors = this.state.authors;
    authors.pop();
    this.setState({authors:authors})

  }
  addAuthorField =() => {
    this.setState({authors:this.state.authors.concat(0)})
  }


  handleNewAuthorChange = (event, key) => {

    let newState = this.state.form_values
    let newAuthor = newState.newAuthor

    newAuthor[event['target']['id']] = event.target.value
    newState['newAuthor'] = newAuthor
    this.setState({"form_values":newState})
  }


  handleAuthorChange = (event, key) => {

    let authorsValue = this.state.authors
    authorsValue[key] = this.state.all_authors.find(element => element.author_id  == event.target.value)
    let newState = {}
    newState["authors"] = authorsValue;
    this.setState(newState)
  }
  handleFileChange = (event) => { 
    console.log(event.target.files)
    const newState = this.state.form_files
    newState[event['target']['id']] = event.target.files
    this.setState({"form_files":newState})
  }
  handleMediaFileChange = (event) => {
    const newState = this.state.media_files
    newState[event['target']['id']] = event.target.files
    this.setState({"media_files":newState})
  }
  handleChange = (event) => {
    let newState = this.state.form_values
    let seriesData = newState.seriesData

    seriesData[event['target']['id']] = event.target.value
    newState['seriesData'] = seriesData
    this.setState({"form_values":newState})
  }

  handleLectureChange= (event) =>{
    console.log(event)

    let newState = this.state
    console.log(newState)

    var val;
    if(event.target.hasOwnProperty("checked")){
      val=event.target.checked
    }else{
      val=event.target.value
    }


    newState['form_values']['lectureData'][event['target']['id']] = val
    this.setState(newState)

  }
   
  handleSubmit = (e) => {
    e.preventDefault()

    let data = this.state.form_values
    console.log(data)
    let key = data.seriesData.key;
    let title = data.seriesData.title    

    let authors =  this.state.authors.map(element => element.author_id)

    const formData = new FormData()
    formData.append("password",this.state.password)                         
    if(Object.keys(data.seriesData).includes("keywords")){
      formData.append("keywords", JSON.stringify(data.seriesData.keywords))
      delete data.seriesData['keywords']
    } 
    formData.append("series", JSON.stringify(data.seriesData))
    formData.append("authors", JSON.stringify(authors))

       for (const property in this.state.form_files){
          formData.append(property, this.state.form_files[property]['0'])
       }
      for (const property in this.state.media_files){
          formData.append(property, this.state.media_files[property]['0'])
       }

      const response =  fetch(CatalogAPI.series_create_url, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: formData // body data type must match "Content-Type" header
        }).then(response => {
          if(response.status >=400){
            this.setState({submitMessage:"Error!"})
          } else {
            this.setState(
              {
                submitMessage:"Saved!",
              }
            )
            this.setState(
              {
                key:key,
                title:title
              }
            )
            setTimeout(() => {  this.changeSlide(1);  }, 1000);
          }
          return response.json()

    }).then(data =>{
                this.setState({
                  series_id:data.series_id
                })
            });
      

  }




  getLectureJSX = () => {
    let iframeJSX = this.state.iframeJSXs.map((element,key) => this.getIframeJSX(key))
    return (      
      <div className="series-new-author">
        
        <Form id="add-lecture-form" onSubmit={this.handleChapterSubmit}>
        <h2>Add Chapter to "{this.state.title}"</h2>
          <Form.Group controlId="title" id="title" >
            <Form.Label>Title</Form.Label>
            <Form.Control type="text" onChange={this.handleLectureChange}/>
          </Form.Group> 
          <Form.Group controlId="author_id">
            <Form.Label>Author</Form.Label>
            <Form.Control as="select" onChange={this.handleLectureChange}>
              <option value="-1"></option>
              {this.state.all_authors.map((element,key) =><option value={element.author_id} key={key}>{element.name}</option>)} 
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="description" id="lecture-desc">
            <Form.Label>Description</Form.Label>
            <Form.Control as="textarea" placeholder="" onChange={this.handleLectureChange}/>
          </Form.Group> 
           <Form.Group controlId="source_id" className="media-form-group">
                  <Form.Label>Source</Form.Label>
                  <Form.Control as="select" onChange={this.handleLectureChange} >
                    <option value="">None</option>
                        <option value="0">Eagle River Institute</option>
                        <option value="1">Orthodox Christian Studies Center</option>
                        <option value="2">IOTA</option>
                  </Form.Control>
                  </Form.Group> 
          <h2>Media</h2>
          <div className="media-wrapper">
            {iframeJSX}
            <div>
              <div className="question-btn-wrapper">
                <div className="answer add-content" onClick={() => {this.setState({iframeJSXs:this.state.iframeJSXs.concat(1)})}}><span><AddIcon/>Add Anothor Media Entry for Content</span></div>
              </div>
            </div>
          </div> 
          <div className="question-btn-wrapper" >
              <Button type="submit" className="lecture-btn-fake">
                <div className="answer yes" onClick={() => {this.setState({addChapter:true})}}>Save and Add another Chapter</div>
                <div className="answer yes" onClick={() => {this.setState({addChapter:false})}}>Save and Exit</div>
              </Button>
              <div className="answer no" onClick={() => window.location.href ="/series/"+this.state.key}><span>Exit</span></div>
          </div>     
      </Form>
      </div>
      )
  }
    handleAuthorSubmit = (e) => {
    e.preventDefault()

     const formData = new FormData()
     formData.append("password",this.state.password)
     formData.append("content", JSON.stringify(this.state.form_values.newAuthor))

     if ('new_author_image' in this.state) {
       formData.append("img", this.state.new_author_image)
     }

    const response =  fetch(CatalogAPI.author_create_url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        //'Content-Type': 'application/json'
         //'Content-Type': 'multipart/form-data',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: formData // body data type must match "Content-Type" header
    }).then( response => {
        if(response.status >=400){
          this.setState({submitMessage:"Error!"})
        } else {
           let state = this.state
           delete state.new_author_image
           this.setState(state)
              fetch(CatalogAPI.authors_url).then(data => data.json()).then(data =>{

                  this.setState({
                    all_authors:data.authors
                  })
            })
          this.setState({submitMessage:"Saved!"})
          setTimeout(() => {  this.changeSlide(1);  }, 1000);
        }


    })
  }
  getSeriesForm = () =>{
    let img, catalogImg = undefined;

    let authors = this.state.authors.map((element,key) => this.getAuthorsOption(key, element.author_id,"author"))

    return (
      <Form onSubmit={this.handleSubmit}>
              <Form.Group readOnly={true} className="hidden-series-id" controlId="formSeriesId">
              <Form.Control defaultValue={this.state.id} type="text" placeholder="" />
            </Form.Group>
              <Form.Group controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control defaultValue={this.state.title} type="text" placeholder="" onChange={this.handleChange}/>
            </Form.Group>
            <Form.Group controlId="subtitle">
              <Form.Label>Subtitle</Form.Label>
              <Form.Control defaultValue={this.state.subtitle} type="text" placeholder="" onChange={this.handleChange}/>
            </Form.Group>
              <Form.Group controlId="key">
              <Form.Label>Key</Form.Label>
              <Form.Control readOnly={!this.state.keyChangeable} defaultValue={this.state.key}  type="text" placeholder="All lower case no punctuation identifier" onChange={this.handleChange}/>
            </Form.Group> 

              <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control className="series-description" defaultValue={this.state.description}  as="textarea"  onChange={this.handleChange}/>
            </Form.Group> 
          <Form.Group controlId="keywords">
              <Form.Label>Keywords</Form.Label>
              <Form.Control  type="text" placeholder="Keywords seperated by a space or comma" onChange={this.handleChange}/>
            </Form.Group>
            
            <Form.Group className="catalog-thumbnail-wrapper">
              <Form.Label>Change / Add Catalog Image</Form.Label>
              {catalogImg}
              <Form.File id="catalogImg"  onChange={this.handleFileChange} />
            </Form.Group>

            <Form.Group className="banner-thumbnail-wrapper">
              <Form.Label>Change / Add Banner Image</Form.Label>
              {img}
              <Form.File id="bannerImg"  onChange={this.handleFileChange} />
            </Form.Group>
            
            <div className="question-btn-wrapper">
              <Button className="answer yes" type="submit"><span>Save and Continue</span></Button>
              <div className="answer no" onClick={() => this.changeSlide(-2)}><span>Cancel</span></div>
            </div>
          </Form>
        )
  }



  changeSlide = (amount) => {
    window.scrollTo(0, 0);

    fetch(CatalogAPI.authors_url).then(data => data.json()).then(data =>{

          this.setState({
            all_authors:data.authors
          })
    })
    this.setState(
      {
        slideIndex:this.state.slideIndex+amount,
        submitMessage:"none"
      }
    )
  }

  getNeedAuthorJSX = () => {

    return (
      <div className="series-question">
        Do you want to create a new Author in the system before starting?
        <div className="question-btn-wrapper">
          <div className="answer yes" onClick={() => this.changeSlide(1)}>Yes</div>
          <div className="answer no" onClick={() => this.changeSlide(2)}>No</div>
        </div>
      </div>
    )
  }

  getIframeJSX = (key) => {
          let attr = {'media_key':key}
          let displayTypePaper = 'none';

          if(this.state.form_values.media_data.length > key){
            if(this.state.form_values.media_data[key].hasOwnProperty('lecture-iframe-type')){
              displayTypePaper = this.state.form_values.media_data[key]['lecture-iframe-type']
            }
          }
            return (
              <div className='iframe-wrapper'>
                <h4>Media Entry {key+1}</h4>
                <Form.Group controlId="lecture-iframe-type" className="media-form-group">
                  <Form.Label>Media Type</Form.Label>
                  <Form.Control as="select" onChange={(event) => this.handleMediaChange(event, key)} {...attr}>
                    <option value="-1">Choose</option>
                        <option value="podcast">Podcast</option>
                        <option value="video">Video</option>
                        <option value="paper">Paper</option>
                  </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="foreign_ids" id="foreign_ids" className={displayTypePaper=='video' || displayTypePaper=='podcast'  ? "":"hide"}>
                    <Form.Label>{displayTypePaper=='video' ? 'Youtube':'Podbean'} Id</Form.Label>
                    <Form.Control type="text" onChange={(event) => this.handleMediaChange(event, key)}/>
                  </Form.Group> 
                  <Form.Group className={"iframe-wrapper-"+key} className={displayTypePaper =='paper' ? "":"hide"}>
                    <Form.Label style={{marginTop:'20px'}}>Upload Paper</Form.Label>
                    <Form.File id={"local_lecture_file-"+key}  onChange={this.handleMediaFileChange}/>
                  </Form.Group>
                  <Form.Group  controlId="book_review" className={"iframe-wrapper-"+key} className={displayTypePaper =='paper' ? "":"hide"}>
                    <Form.Label style={{marginTop:'20px'}}>Book Review</Form.Label>
                    <Form.Check onChange={(event) => this.handleLectureChange(event)}/>
                  </Form.Group>                  
                 
               
              </div>
            )
  }

  handleMediaChange = (event, key) => {
    let newState = this.state
    let media_data = newState['form_values']['media_data']
    if(media_data.length == key){
      media_data[key] = {}
    }
    var val;
    if(event.target.hasOwnProperty("checked")){
      val=event.target.checked
    }else{
      val=event.target.value
    }
    media_data[key][event['target']['id']] = val
    newState['form_values']['media_data'] = media_data
    this.setState(newState)
 } 

 resetChapterForm = () => {
    let form_values = this.state.form_values
    form_values.lectureData = {}
    form_values.media_data = []
    document.getElementById("add-lecture-form").reset();

  this.setState({
      form_values:form_values,
      iframeJSXs:[0]
  })
  this.changeSlide(1)

 }

  handleChapterSubmit = (e) => {
    e.preventDefault()
     const formData = new FormData()
     formData.append("password",this.state.password)
     formData.append("series_id", this.state.series_id)

     formData.append("lectureData", JSON.stringify(this.state.form_values.lectureData))
     formData.append("mediaData", JSON.stringify(this.state.form_values.media_data))

     for (const property in this.state.media_files){
        formData.append(property, this.state.media_files[property]['0'])
     }
    const response =  fetch(CatalogAPI.chapter_create_url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        //'Content-Type': 'application/json'
        //'Content-Type': 'multipart/form-data',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: formData // body data type must match "Content-Type" header
    }).then( response => {
        if(response.status >=400){
          this.setState({submitMessage:"Error!"})
        } else {
          this.setState({submitMessage:"Saved!"})

          if(this.state.addChapter){
            this.resetChapterForm()
          } else {
            setTimeout(() => {  
              this.changeSlide(1);
              this.setState({submitMessage:"Saved, lets see it!"})
              window.location.href ="/series/"+this.state.key
            }, 2000);

          }



        }


    })
  }

  render() {
    let submitShow = this.state.submitMessage == "none" ? "hidden ":"submit-message-bar ";
    let submitResultClass = this.state.submitMessage == "success" ? "red":"green"
    let submitMessageBarClass = submitShow+submitResultClass


    let content;
    if(this.state.slideIndex == 0){
      content= this.getNeedAuthorJSX()
    } else if(this.state.slideIndex  == 1){
      content= this.authorInput(true,0)
    } else if(this.state.slideIndex == 2){
      content = this.getSeriesForm()
    } else if(this.state.slideIndex >= 3){
      content= this.getLectureJSX()
    }
    console.log(this.state)
    return (
      <div className="admin">
            <div className={submitMessageBarClass}><span>{this.state.submitMessage}</span></div>
        
        <div className={this.state.correctPassword ?  'password-wrapper hidden':'password-wrapper'}>
          <form className="password" autocomplete="on" onSubmit={(e)=>this.checkPassword(e)}>
            <h2>Authentication Required</h2>
            <input style={this.state.addShake ? {"animation": "shake 0.5s",boxShadow: "0 0 0.5em red"}:{}} ref={this.passwordInput} type="password" placeholder="Password..." onChange={(e) => {this.setState({password:e.target.value})}} autofocus="true"/>
            <img className={this.state.checkingPassword ? "thinking-wheel":"hidden"} src={this.images['thinking']} />

            <br/>
              <button className="submit-password" type="submit">Submit</button>
              <Link className="cancel-password" to="/admin">Cancel</Link>
          </form>
        </div>
        <main className="site-content eri-colors add-series create-series">
          <h1>Add Media Library Entry</h1>
            {content}
          
        </main>
        </div>
      
      )
    }
}

export default AddSeries 

//          

