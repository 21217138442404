import Button from 'react-bootstrap/Button';
import DescriptionIcon from '@material-ui/icons/Description';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import * as CatalogAPI from "./CatalogAPI"

export const  getLetter = (index) =>{
	return "ABCDEFGHIJKLNMOPQRSTUVWXYZ".charAt(index)
}
export const getNumberSpelled = (index) => {
			const numbers = ['Zero','One','Two','Three','Four','Five','Six','Seven','Eight','Nine','Ten','Eleven','Twelve','Thireen','Fourteen','Fifteen','Sixteen','Seventeen','Eighteen','Nineteen','Twenty']
			return numbers[index]
}

export const getSidebarJSX = (sidebarItem,key) => {

	const href = '#lecture-'+(key+1)
	const numberSpelled =getNumberSpelled(key+1)
	return (<div className="lecture-wrapper" key={key}>
		<div><b>{numberSpelled}</b></div>
		<a className='series-lecture-sidebar-link' href={href}>{sidebarItem.title}</a>
		<div>{sidebarItem.author_short}</div>
	</div>
	)
}


export const getLectureJSX = (element,key, resourceMap) => {
	const lecture_id = "lecture-"+(key+1)

	const includesMultipleLectures = element.iframes.length > 1

	const getLectureIframe = (iframeInfo, key,title, includesMultipleLectures,lecture_id) => {
		if(iframeInfo != null){
			console.log("!!??!!")
			console.log(iframeInfo)
			let id = iframeInfo.split("$$")[1]
			let host = iframeInfo.split('$$')[2]
			console.log(host)
			if(host == 'podbean'){
				const iframeUrl = "https://www.podbean.com/media/player/"+id+"?from=pb6admin&download=1&version=1&skin=1&btn-skin=107&auto=0&download=1&pbad=1"
				return (
					<div className="iframe-wrapper" key={key}>
							<div className={includesMultipleLectures ? 'lecture-part-label':'hidden'}>Part {getLetter(key)}</div>
							<iframe title={title} height="122" width="100%"  scrolling="no" data-name="pb-iframe-player" src={iframeUrl}></iframe>
					</div>
				)
			}else if (host =='soundcloud'){
				const iframeUrl = "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/"+id+"&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
				return (
					<div className="iframe-wrapper" key={key}>
							<div className={includesMultipleLectures ? 'lecture-part-label':'hidden'}>Part {getLetter(key)}</div>
							<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src={iframeUrl}></iframe>
					</div>
				)
			} else if (host =='youtube'){
				const iframeUrl = "https://www.youtube.com/embed/"+id
				return (
					<div className="iframe-wrapper" key={key}>
							<div className={includesMultipleLectures ? 'lecture-part-label':'hidden'}>Part {getLetter(key)}</div>
							<iframe width="888" height="500" src={iframeUrl} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
					</div>
				)
			} else if (host =='local'){
				return(
					<div className="iframe-wrapper" key={key}>
						
						<a href={CatalogAPI.getPaperURL(lecture_id, id)} target="_blank">
						<Button className="download-paper">
							<CloudDownloadIcon />
							Download {id}
							</Button>
						</a>

					</div>
				)
			} 


		}
	}

	let iframeList= element.iframes.map((id,key) => getLectureIframe(id,key,element.title, includesMultipleLectures,element.lecture_id))

	
	const lectureCount= getNumberSpelled(key+1)
	const lectureTitle = element.title

	let resourceMapJSX = "";
	console.log("resourceMap is null")
	console.log(resourceMap)
	if (resourceMap != null){
		if( resourceMap[element.lecture_id] != null) {
			resourceMapJSX = <div><h2 className="slides-and-handouts">Slides and Handouts</h2><ul>{resourceMap[element.lecture_id]}</ul></div>
		}
	}	

		// let lis =  = resourceMap[element.lecture_id][0];



	return (
        <div className="lecture" key={key}  id={lecture_id}>

   			<h4 className="lecture-title">
   				{lectureCount}
   				</h4>
   			<h3 className="lecture-title">
   				{lectureTitle}
   				
   			</h3>

   			<div className="lecture-author">
   				{element.author}
   			</div> 
   			{iframeList}

   			<div className="lecture-description">
   				{element.description}
   			</div>
   			<div>
   				{resourceMapJSX}
   			</div>
		</div>
	)
}
	export const getAuthorJSX = (element, key, image) => {
				       console.log(image)
				       return( <div className="speaker-bio" key={key}>
		               		<div className="bio-pic-wrapper">
			               		<img
				           		className="bio-pic"
				           		src={image}
				               	alt="" />
				            <div className="speaker-title">{element.longName}</div>
		               		</div>
		               		<div className="bio-text">
		               			{element.biotext}
		               		</div>

		               	</div>
	)




}
export const getResources = (obj) => {
		console.log(obj)
		if (obj == null || obj[0] == null){
			return <div></div>
		} else {

			const links = Object.entries(obj).map((a) => getResource(a))
			return ( 
				<div className="lecture-resources">
					<h2>Lecture Resources</h2>
					
					<ul>{links}</ul>
				</div>
			)	

		}
}
export const getResourcesMap = (obj) => {
		console.log(obj)
			if (obj[0] != null) {
                let linkMap ={};
                console.log("link map is empty. Starting to add links to linkmap")

                let addLink = (id,array) => {
                	console.log("cheking for id in linkmap:" +id)
                	console.log("exising linkMap: "+JSON.stringify(linkMap))
                	console.log("is it in there: " +id in linkMap)
                	console.log("array:")
                	console.log(JSON.stringify(array))
                   if (id in linkMap){
                       linkMap[id] = linkMap[id].concat(<li><a key={id} href={array[1]} target="_blank">{array[0]}</a></li>)
                   } else {
                       linkMap[id] = [<li><a key={id} href={array[1]} target="_blank">{array[0]}</a></li>]
                   }
                   console.log("new linkMap: "+JSON.stringify(linkMap))

                }
				Object.entries(obj).forEach(a => addLink(a[1].split('$$')[2], a[1].split('$$')))

				console.log("sending this linkmap back to user: ")
				console.log(JSON.stringify(linkMap))
				return linkMap
			} else {
				return null
			}
}

const getResource = (c) => {

	console.log(c)
	let a = c[1].split('$$')

	console.log(a)
	let link = <li><a key={c[0]} href={a[1]} target="_blank">{a[0]}</a></li>
	console.log(JSON.stringify(link))
	return link
}