import React from "react"
import Button from 'react-bootstrap/Button';

 import {

  Link
} from "react-router-dom";
import logo from "../data/images/home/logo1.png"
function ERI (data) {
	window.scrollTo(0, 0);
	const backText="< Back"
	return (
			
			<main className="site-content eri-colors register">
<iframe width="100%" height="20" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1104903571&color=%230066cc&inverse=false&auto_play=false&show_user=true"></iframe>
			</main>
		
	)
}

export default ERI 
