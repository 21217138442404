import React, {Component} from "react"

import ReactDOM from "react-dom";
import Button from 'react-bootstrap/Button';

import HeadsetIcon from '@material-ui/icons/Headset';
import VideocamIcon from '@material-ui/icons/Videocam';
import PaperIcon from '@material-ui/icons/Description';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import "../styles/eri-series.css"
import "../styles/catalog.css"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import * as CatalogAPI from "../utils/CatalogAPI"


class SpecialLectureSeries extends Component{
  constructor(props){
    super(props)
    window.scrollTo(0, 0);

console.log(CatalogAPI.catalog_url)
    this.passwordInput = React.createRef();

    this.images =  require("../utils/ImageUtils")
    this.state ={
          filterValue: "",
          lectureVisible: Array().fill(true),
          keywords:[],
          series:[],
          showTypes:{
            podcast:true,
            video:true,
            paper:true
          },
          sortDown:true,
          sortBy:"id"
    }

    }
  
  componentDidMount() { 
    let allKeywords = []

    fetch(
        CatalogAPI.catalog_url
      ).then(res =>res.json()).then( data => {
        data.keywords.forEach((element,key) => allKeywords[key]={value:element,selected:false})
        this.setState({
          keywords:data.keywords,
          allKeywords: allKeywords,
          series:data.series
        })
      })

  }

  setFilterValue = (value) => {
    this.setState({filterValue:value})   
  }

  checkFilterValue = (words) =>{

    if(this.state.filterValue == '' ){
      return true;
    }
    let contains = this.state.filterValue.toLowerCase().split(" ").map(element => this.checkSingleWord(element, words))

    let result = true

    for (const contain of contains){
      if(!contain.includes(true)){
        result = false
      }
    }
    return result;
  }
  checkSingleWord = (filterWord, keywords) => {
    var contains = [false];
    try {
      contains = keywords.map(word => word.includes(filterWord))
    } catch(element){
      
    }
    return contains
  }

  checkKeywordsSelected = (words) => {
    let contains = this.state.allKeywords.map(element => (!element.selected || words.includes(element.value.toLowerCase())))
    return (!contains.includes(false)) 
  }
  getSeriesJSX = (data,key) =>{
    const image = this.images.getCatalogImage(data['id'])
    const href = "/series/"+data.key
    let authors;
    if (data.authors.length < 3){
      authors = data.authors.map((element,key) => <div key={key}>{element}</div>)
    } else {
      authors = data.authors.length + " Contributors"
    }


    let source_logos = data.source_id.map((x,i) => <div className="source-wrapper"><img className="source-logo" src={CatalogAPI.getSourceImage(x)} alt=""/><span>{data.source_name[i]}</span></div>)

    let subtitle = ""
    if (data.subtitle != null){
      subtitle = data.subtitle.toUpperCase()
    }
    let book_review = null;
      return(<li className={(
            this.checkFilterValue(data.keywords) && 
            this.checkKeywordsSelected(data.keywords) && 
            (this.state.showTypes[data.media_types[0]] || data.media_types[0] == null)
          )? '':'hide'
          } key={key}>
          <div  className="series-lecture" href={href}>

            <Link to={href}  className="logo-wrapper">
              <div className="series-lecture-logo-wrapper">
                <img
                  className="series-lecture-logo"
                  src={image}
                    alt="not found" onError={(e)=>{e.target.onerror = null; e.target.src=this.images.getCatalogImage(9999)}}  />
              </div>
            </Link>
            <div className="lecture-metadata">
                            <span className="bottom-link" to={href}>
                     <div className="lecture-count" dangerouslySetInnerHTML={{ __html: data.lectures }}></div>
                     
                    <HeadsetIcon className="type-icon"  id={data.media_types.includes('podcast') ? 'not-hidden':'hide'}/>
                    <VideocamIcon className="type-icon"   id={data.media_types.includes('video') ? 'not-hidden':'hide'}/>
                    <PaperIcon className="type-icon"  id={data.media_types.includes('paper') ? 'not-hidden':'hide'}/>
                    {source_logos}
                 </span>
                <Link to={href} className="lecture-title">
                <div className="lecture-metadata-title">{data.title.toUpperCase()}</div>
                <div className="lecture-metadata-subtitle">{subtitle}</div>

                </Link>

                <div className="lecture-author">
                <div style={{display:(data.book_review? 'inline-block':'none'),float:'left'}}>

                  <MenuBookIcon style={{fontSize:'20px'}}/>
                  <span style={{margin:'0px 7px 0px 5px',color:"darkred"}}>Book Review by</span>
                </div>
                {authors}
                </div>
                <div className="lecture-description">
                  {data.description}
    

                </div>
               

            </div>
                </div>
      </li>)

  }
  changeSelectedKeyword =(key) => {
    let allKeywords = this.state.allKeywords;
    allKeywords[key].selected = !allKeywords[key].selected
    this.setState({"allKeywords":allKeywords})
  }
  getKeywordsJSX = (element,key) =>{
    return (
      <li className={this.state.allKeywords[key].selected == true ? "selected":"unselected"} key={key} onClick={() => this.changeSelectedKeyword(key)}>
        <Button><span>X</span>{element} </Button>
      </li>
      )
  }
  toggleMediaType = (e) => {
    
    var mediaType = e.target.value
    let showTypes = this.state.showTypes
    if(mediaType!==''){
      Object.keys(showTypes).forEach(v => showTypes[v] = false)
      showTypes[mediaType] = true
    } else {
      Object.keys(showTypes).forEach(v => showTypes[v] = true)
    }
    this.setState({showTypes:showTypes})
  }
  changeSortDirection = () => {
    this.setState(
      {
        sortDown:!this.state.sortDown,
        series:this.state.series.reverse()
      }
    )
  }
  sort = (e) => {
        var sort_type = e.target.value
        console.log(sort_type)

        var series = this.state.series

        series = series.sort((a, b) => (b[sort_type] > a[sort_type]) ? 1 : (a[sort_type] === b[sort_type]) ? ((b.id > a.id) ? 1 : -1) : -1 )

        this.setState(
          {
            sortDown:true,
            sortBy:sort_type
          }
        )
  }
  render(){
    const series = this.state.series.map((element,key) => this.getSeriesJSX(element,key))
    const keywords = this.state.keywords.map((element,key) => this.getKeywordsJSX(element,key))
    let style = {}
    if (process.env.REACT_APP_BANNER == "false"){
        style = {paddingTop:"10px"}
    }
    return (
      <div className="background">
      <div style={style} className="special-lecture-series">
      
      <div className="lecture-list-wrapper">
      <h1>{process.env.REACT_APP_LIBRARY_NAME}</h1>
        <div className="lecture-list-filter-wrapper">
          <div className="input-wrapper">
            <span onClick={(e) => document.getElementById("lecture-list-filter").focus()}>Search: </span>
            <input placeholder="Titles, Authors, Content..." type="text" id="lecture-list-filter" onChange={(event) => this.setFilterValue(event.target.value)}/>
          </div>
          <div className="media-type">
            <label htmlFor="media_type">Show:</label>
            <select id="media_types" name="media_types"  onChange={(e) => this.toggleMediaType(e)}>
              <option value="">All Media</option>
              <option value="podcast">Podcasts</option>
              <option value="video">Videos</option>
              <option value="paper">Papers</option>
            </select>

          </div>
          <div className="sort">
            <label htmlFor="sort">Sort:</label>
            <select id="sort" name="sort"  onChange={(e) => this.sort(e)}>
              <option value="id">Date Added</option>
              <option value="recent_views">Recent Favorites</option>
              <option value="page_views">Most Viewed</option>
            </select>
            <span onClick={() => this.changeSortDirection()}>
              <ArrowDownwardIcon id={this.state.sortDown ? 'not-hidden':'hide'}/>
              <ArrowUpwardIcon id={!this.state.sortDown ? 'not-hidden':'hide'}/>
            </span>
          </div>          
        </div>
          <div className="lists-wrapper">

        <ul className="series-lecture-list">
          {series}
        </ul>
      </div>
      </div>
      </div>
    </div>
    )
  
  }
}
export default SpecialLectureSeries

