import React, {Component} from "react"

import ReactDOM from "react-dom";
import Button from 'react-bootstrap/Button';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import "../styles/eri-series.css"
import "../styles/catalog.css"
import "../styles/admin.css"
import "../styles/admin-add.css"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import * as CatalogAPI from "../utils/CatalogAPI"


class SpecialLectureSeries extends Component{
  constructor(props){
    super(props)

    console.log(CatalogAPI.catalog_url)

        this.images =  require("../utils/ImageUtils").getImagesFromDir("catalog")

        this.state ={
              filterValue: "",
              lectureVisible: Array().fill(true),
              keywords:[],
              series:[]
        }

    }

  checkPassword = () => {
    const formData = new FormData()

      formData.append("password", this.state.password)

    fetch(CatalogAPI.checkPassword(), {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        //'Content-Type': 'application/json'
         //'Content-Type': 'multipart/form-data',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: formData // body data type must match "Content-Type" header
    }).then( response => {
        if(response.status >=400){
          this.setState({submitMessage:"Bad Password!!"})
          setTimeout(() => {  this.setState({submitMessage:"none"}) }, 1000);
        } else {
          this.setState({submitMessage:"Success!"})
          setTimeout(() => {  
            this.setState({submitMessage:"none","correctPassword":true})

           }, 500);
        }


    })
  }
  
  componentDidMount() { 
    let allKeywords = []

    fetch(
        CatalogAPI.catalog_url
      ).then(res =>res.json()).then( data => {
        data.keywords.forEach((element,key) => allKeywords[key]={value:element,selected:false})
        this.setState({
          keywords:data.keywords,
          allKeywords: allKeywords,
          series:data.series
        })
      })

  }

  setFilterValue = (value) => {
    this.setState({filterValue:value})   
  }

  checkFilterValue = (words) =>{

    if(this.state.filterValue == '' ){
      return true;
    }
    let contains = this.state.filterValue.toLowerCase().split(" ").map(element => this.checkSingleWord(element, words))
    return contains[0].includes(true);
  }
  checkSingleWord = (filterWord, keywords) => {
    const contains = keywords.map(word => word.includes(filterWord))
    return contains
  }
  checkKeywordsSelected = (words) => {
    let contains = this.state.allKeywords.map(element => (!element.selected || words.includes(element.value.toLowerCase())))
    return (!contains.includes(false)) 
  }
  getSeriesJSX = (data,key) =>{

    console.log(data.key)

    const image = this.images[data.key]
    const href = "/admin/series/"+data.key
      return(<li className={(this.checkFilterValue(data.keywords) && this.checkKeywordsSelected(data.keywords))? '':'hide'} key={key}>
          <Link  className="series-lecture" to={href}>

            <div className="lecture-metadata">
                <div to={href} className="lecture-title">
                {data.title}
                </div>

                <div>
                {data.authors.map((element,key) => <div key={key}>{element}</div>)}
                </div>
            </div>
                </Link>
      </li>)

  }
  changeSelectedKeyword =(key) => {
    let allKeywords = this.state.allKeywords;
    allKeywords[key].selected = !allKeywords[key].selected
    this.setState({"allKeywords":allKeywords})
  }
  getKeywordsJSX = (element,key) =>{
    return (
      <li className={this.state.allKeywords[key].selected == true ? "selected":"unselected"} key={key} onClick={() => this.changeSelectedKeyword(key)}>
        <Button>{element} <span>X</span></Button>
      </li>
      )
  }
  render(){
    console.log(this.state)
    const series = this.state.series.map((element,key) => this.getSeriesJSX(element,key))
    const keywords = this.state.keywords.map((element,key) => this.getKeywordsJSX(element,key))

    return (
      <div className="admin">
      <div className="special-lecture-series">
      <h1>Admin</h1>
      <h2>Special Lecture Series</h2>
      <div className="lecture-list-wrapper">
         <Link className="back" to="/admin">
              <Button> <ArrowBackIosIcon/> Back </Button>
          </Link>     
        <div className="lecture-list-filter-wrapper">


          <input placeholder="Search Lectures..." type="text" id="lecture-list-filter" onChange={(event) => this.setFilterValue(event.target.value)}/>
        </div>
          <div className="lists-wrapper">
        <ul className="series-lecture-list">
          {series}
        </ul>
      </div>
      </div>
    </div>
    </div>
    )
  
  }
}
export default SpecialLectureSeries

